@tailwind base;
@tailwind components;
@tailwind utilities;

/* SCROLLBAR STYLE */
/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b9b9b9;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.tooltip-text {
  visibility: hidden;
  width: 9rem;
  background-color: rgb(0, 12, 51);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  position: absolute;
  top: 55%; /* Position above the element */
  right: 0%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

/* Tooltip arrow */
.tooltip-text::after {
  content: "";
  position: absolute;
  top: 0%; /* Bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: rgb(0, 12, 53) transparent transparent transparent; /* Arrow */
}

/* Show the tooltip on hover */
td:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.loader {
  width: 50px;
  height: 50px;
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 100px;
}